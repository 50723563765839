import { Environment } from '@integration-frontends/apps/common/utils/environment-types';

export const environment = {
  production: true,
  bfBaseUrl: 'https://brandfolder.com/api',
  bfWebsiteBaseUrl: 'https://brandfolder.com',
  bfStaticAssetsBaseUrl: 'https://static-fast.brandfolder.com',
  segmentWriteKey: 'mXNgAITR8qQXLuSX6OlUYlzkdn5wFtMD',
  environment: Environment.Production,
};
